<template lang="pug">
.modal-backdrop(role='dialog' :class="{'modal-visible': visible, 'modal-invisible': !visible}")
	.modal-overlay(@click='close')

	.modal.modal_bottom
		button.btn-close(type='button' @click='close' aria-label='Close modal')
			svg-icon(icon-name="close")

		.footermodal

			.flexbox
				.footermodal__item(:class="{'animated showFromBottom': visible}")
					h5 Technologies
					p Vue.js, PUG, SCSS
				.footermodal__item(:class="{'animated showFromBottom delay-025s': visible}")
					h5 Animations
					p JavaScript & CSS + SVG
				.footermodal__item(:class="{'animated showFromBottom delay-05s': visible}")
					h5 Developed by
					p
						span.link-arrow
							a.link(href="https://yegupov.github.io/" target="_blank") Gennady Yegupov
</template>

<script>
export default {
	name: 'FooterModal',

	props: {
		visible: Boolean
	},

	components: {
		SvgIcon: () => import('@/components/SvgIcon')
	},

	methods: {
		close() {
			this.$emit('close');
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.footermodal {

	.flexbox {

		@include tablet() {
			flex-direction: column;
		}

		.footermodal__item {

			@include tablet() {
				width: 100%;
			}

			@include phoneSmall() {
				padding: 7px 0;
			}

			h5 {
				margin: 0;
				font-size: 0.85rem;
				text-transform: uppercase;

				@include phone() {
					color: $text-color;
				}
			}

			p, .link {
				margin: 0;
				padding: 0 0 20px;
				font-size: 1.7rem;
				font-weight: 600;
				line-height: 1.4;
				text-transform: none;

				@include netbook() {
					font-size: 1.4rem;
				}

				@include tablet() {
					font-size: 1.7rem;
				}

				@include phone() {
					color: $text-color;
				}

				@include phoneSmall() {
					font-size: 1.2rem;
				}
			}

			.link-arrow:after {
				content: "🡥";
				display: inline-block;
				width: 10px;
				height: 10px;
				padding-left: 5px;
				font-size: 1em;
			}
		}
	}
}
</style>
